<template>
  <div :class="$bem()">
    <div :class="$bem('filter')">
      <filters />
    </div>
    <div v-if="mobileOnly">
      <mobile-header :objectsCount="objects.length" :totalCount="count" />
      <div :class="$bem('cards-wrapper')">
        <div :class="$bem('cards-mobile')">
          <div v-for="apt in objects" :key="apt.id">
            <catalog-card :apartment="apt" />
          </div>
        </div>
        <infinite-loading @infinite="infiniteHandler">
          <div slot="spinner" />
        </infinite-loading>
      </div>
    </div>

    <div :class="$bem('cards-wrapper')" v-if="desktopOnly">
      <div :class="$bem('cards-desktop')" ref="cardsDesktop">
        <div v-for="apt in objects" :key="apt.id">
          <catalog-card :apartment="apt" />
        </div>
      </div>
      <counter :objects="objects.length" :count="count" />
    </div>
  </div>
</template>

<script>
import component from '@/mixins/component';
import mediaQueries from '@/mixins/mediaQueries';
import { mapState, mapActions, mapMutations } from 'vuex';
import { NOTIFICATIONS } from '@/helpers/constants';

export default {
  name: 'catalog',
  components: {
    Filters: () => import('@/components/app/Filters'),
    MobileHeader: () => import('@/components/app/mobile-header'),
    CatalogCard: () => import('@/components/app/CatalogCard'),
    Counter: () => import('..//counter/counter.vue'),
    InfiniteLoading: () => import('vue-infinite-loading')
  },
  props: {
    isArchive: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState({
      objects: (state) => state.main.objects,
      count: (state) => state.main.count,
      page: (state) => state.main.page,
      loaderObject: (state) => state.main.loaderObject
    })
  },
  methods: {
    ...mapActions(['getObjects']),
    ...mapMutations(['SET_PAGE']),

    scrollToEnd() {
      if (!this.page) {
        this.$notify({
          group: 'app',
          type: 'success',
          text: NOTIFICATIONS.viewedAllObjects,
          ignoreDuplicates: true
        });
        return;
      }
      this.getObjects();
    },
    async infiniteHandler($state) {
      if (this.$store.state.main.page) {
        if (this.$store.state.main.page === 1) {
          await this.getObjects({ isSearchById: false, getLastFilter: true });
        } else {
          await this.getObjects();
        }
        $state.loaded();
      }
    },
    setupHorizontalScroll(event) {
      if (this.$refs.cardsDesktop) {
        if (!event.target.closest('.product-modal, .partner-review-wrapper')) {
          // Добавляем горизонтальное движение
          this.$refs.cardsDesktop.scrollLeft += event.deltaX / 2;

          // Обработка вертикального движения как раньше
          this.$refs.cardsDesktop.scrollLeft += event.deltaY / 2;
          event.preventDefault();
        }
      }
    },
    checkScrollPosition(event) {
      let target = event.target;
      // Проверка, что блок прокручен до конца
      if (
        target.scrollLeft + target.offsetWidth + 300 >= target.scrollWidth &&
        !this.loaderObject
      ) {
        this.scrollToEnd();
      }
    }
  },
  mounted() {
    this.SET_PAGE(1);
    this.getObjects({ isSearchById: false, getLastFilter: true });
    this.$nextTick(() => {
      if (this.$refs.cardsDesktop) {
        this.$refs.cardsDesktop.addEventListener(
          'wheel',
          this.setupHorizontalScroll
        );
        this.$refs.cardsDesktop.addEventListener(
          'scroll',
          this.checkScrollPosition
        );
      }
    });
  },
  beforeDestroy() {
    if (this.$refs.cardsDesktop) {
      this.$refs.cardsDesktop.removeEventListener(
        'wheel',
        this.setupHorizontalScroll
      );
      this.$refs.cardsDesktop.removeEventListener(
        'scroll',
        this.checkScrollPosition
      );
    }
  },
  mixins: [component, mediaQueries]
};
</script>

<style lang="sass">
.catalog
  &__filter
    width: 267px
    flex-shrink: 0
    position: relative
    box-shadow: 0 0 15px 5px rgb(21, 51, 80)
    padding-top: 10px
    @include media-breakpoint-down(md)
      display: none
  &__cards-wrapper
    width: 100%
    height: 100%
    display: flex
    flex-direction: column
  &__cards-mobile
    display: flex
    margin: 0
    margin-top: 50px
    flex-direction: column
  &__cards-desktop
    display: flex
    margin: 15px 10px
    flex-direction: row
    overflow: auto
    scrollbar-width: thin
    scrollbar-color: darkgrey #f4f4f4
    width: calc(100vw - 285px)
    &::-webkit-scrollbar
      height: 14px
      padding-left: 20px
      position: absolute

    &::-webkit-scrollbar-thumb
      background-color: rgba(78, 111, 144, 0.5)
      border-radius: 10px

    &::-webkit-scrollbar-track
      background-color: rgba(56,84,106,.2)
      border-radius: 10px
</style>
